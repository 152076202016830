import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function Images({ src, ...props }) {
  // выключаю картинки которые не используются в этом компоненте через `nin`
  // это нужно для того чтобы не проганять их через `sharp` два раза
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          internal: { mediaType: { regex: "/image/(jpe?g|png|webp)/" } }
          sourceInstanceName: { eq: "images" }
          relativePath: {
            nin: [
              "szv-photo.jpg"
              "ios-app.jpg"
              "support.jpg"
              "image.jpg"
              "image2.jpg"
              "image3.jpg"
            ]
          }
        }
      ) {
        nodes {
          relativePath
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const imageNode = useMemo(
    () => data.images.nodes.find(({ relativePath }) => src === relativePath),
    [data, src]
  );

  const gatsbyImageData = getImage(imageNode);
  const publicURL = imageNode?.publicURL;

  return gatsbyImageData ? (
    <GatsbyImage image={gatsbyImageData} {...props} />
  ) : (
    <img {...props} src={publicURL} />
  );
}

export default Images;
